<template>
  <div class="header-fixed-wrapper">
    <div class="header-wrapper">
      <div class="full-bg" />
      <div class="title-wrapper flex v-center h-between mh-auto">
        <div class="title font12 flex v-center">
          <el-link :underline="false" @click.stop="() => winOpen('/')">
            <span>欢迎进入</span>
            <span style="color: #F4552D">优管网</span>
          </el-link>
          <div class="address-select-wrapper ml5">
            <el-select popper-class="address-popper" v-model="address" class="address-select" filterable
              @change="handleProvinceChange">
              <template #prefix>
                <i class="el-icon-map-location font16" />
              </template>
              <el-option label="全国" value="全国" />
              <el-option :label="province.name" :value="province.name" v-for="province in provinceList"
                :key="province.id" />
            </el-select>
          </div>
        </div>
        <div class="right pos-rel font14 flex v-center">
          <el-link :underline="false" class="mr20" @click.stop="() => $router.replace('/')">首页</el-link>
          <el-link :underline="false" class="mr20" @click.stop="() => winOpenName('ApplicationDownload')">应用下载</el-link>
          <el-link :underline="false" class="mr20" @click.stop="() => $router.replace('/cooperation')"
            v-if="systemConfig.hideCollaboration != 1">寻找合作</el-link>
          <el-link :underline="false" class="mr20" @click.stop="done(() => winOpen('/myPriceTrade'))">我的询价</el-link>
          <el-link :underline="false" class="mr20" @click.stop="handleOpenShop">商家入驻</el-link>
          <el-link :underline="false" class="mr20" @click.stop="done(() => winOpen('/cart'))">
            <img src="@/assets/images/anchor/cart_icon.png" width="18" style="vertical-align: -3px;" class="mr5"/>
            <span>购物车</span>
          </el-link>
          <el-link :underline="false" class="mr20" @click.stop="toBackStage('/my/message')">
            <el-badge is-dot v-if="count > 0">
              <i class="font16 el-icon-bell" />
            </el-badge>
            <i class="font16 el-icon-bell" v-else />
          </el-link>
          <el-button type="danger" class="center-self" plain size="mini" @click.stop="toBackStage('/user/profile')"
            v-if="!shopId">个人中心</el-button>
          <el-button type="warning" plain size="mini" @click.stop="toBackStage()" v-else>控制台</el-button>
        </div>
      </div>
    </div>
    <open-shop-dialog v-model="showOpenShop" />
  </div>
</template>

<script>
import OpenShopDialog from '../business/openShopDialog.vue';
import messageApi from '@/api/message'
import shopApi from '@/api/shop'
import event from '@/utils/event';
import { throttle } from 'lodash'
import { ADDRESS, SET_ADDRESS } from '@/store/constant';
import system from "@/mixins/system";
import { mapState } from 'vuex';

export default {
  name: "Header",
  inject: ["toBackStage", 'updateQuery'],
  mixins: [system],
  components: { OpenShopDialog },
  data() {
    return {
      showOpenShop: false,
      openShopLoading: false,
      count: 0
    }
  },
  computed: {
    ...mapState(['provinceList']),
    shopId() {
      return this.$store?.getters?.user.shopId || null;
    },
    address: {
      get() {
        return this.$store.state[ADDRESS]
      },
      set(val) {
        this.$store.commit(SET_ADDRESS, val)
      }
    }
  },
  methods: {
    handleProvinceChange() {
      this.updateQuery({ t: Date.now() }, true)
    },
    async handleOpenShop() {
      if (!this.isLogin) {
        return this.showOpenShop = true;
      }
      if (this.openShopLoading) {
        return;
      }
      this.openShopLoading = true;
      try {
        const canOpenRes = await shopApi.canOpen();
        const canOpen = canOpenRes.data;
        if (!canOpen) {
          return this.$message.warning("您已开店");
        }
      } catch (e) { }
      this.openShopLoading = false;
      this.showOpenShop = true;
    },
  },
  created() {
    const throttleFn = throttle(() => {
      this.done(async () => {
        const res = await messageApi.count().catch(console.error)
        if (!res) {
          return
        }
        this.count = res.data || 0;
      }, 'login', false)
    }, 1000, { trailing: true, leading: false })
    event.addListener('router:afterEach', throttleFn)
  }
};
</script>
<style lang="less">
.header-fixed-wrapper {
  padding-top: var(--header-height);

  .header-wrapper {
    flex: none;
    height: var(--header-height);
    position: fixed !important;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    z-index: 12;
    background-color: var(--layout-bg-color);

    .center-self {
      color: #F4552D!important; 
      border-color: #F4552D!important;
      background-color: transparent;
      &:hover{
        background-color: #f5795aa4;
        color: #fff!important;
      } 
    }

    .full-bg {
      background-color: var(--layout-bg-color);
    }

    .title-wrapper {
      width: var(--layout-width);
      height: inherit;
      position: relative;
      z-index: 13;

      .el-link.el-link--default:hover {
        color: #F4552D;
      }

      .el-button--mini,
      .el-button--mini.is-round {
        padding: 5px 8px;
      }

      .right {
        color: #8a8a8a;
        height: inherit;
        line-height: inherit;
      }
    }
  }

  .header-replace {
    width: 100%;
    height: var(--header-height);
    z-index: -1;
    position: relative;
    background: transparent;
  }

  .address-select {
    .el-input__inner {
      border: none;
      background-color: transparent;
    }

    .el-input__suffix {
      display: none;
    }

    .el-input__prefix {
      display: flex;
      align-items: center;
      color: #000;
    }
  }
}

.address-popper {
  .el-select-dropdown__item.selected {
    color: #F4552D;
  }
}
</style>
