<template>
  <div id="app" class="app" :class="[activeRouteClass]">
    <router-view v-if="reload" />
  </div>
</template>

<script>
import { filterEmpty } from "@/utils";
import system from "@/mixins/system";
export default {
  name: "App",
  mixins: [system],
  data() {
    return {
      reload: true,
    }
  },
  computed: {
    activeRouteClass() {
      return this.$route.path.replace(/\//g, '')
    }
  },
  provide() {
    const self = this;
    return {
      reloadApp() {
        self.reload = false;
        setTimeout(() => {
          self.reload = true;
        }, 100);
      },
      updateQuery(params, needReload = true) {
        const query = filterEmpty({
          ...this.$route.query,
          ...params,
        });
        this.$router.replace({
          path: this.$route.path,
          query,
        });
        if (!needReload) {
          return
        }
        self.reload = false;
        setTimeout(() => {
          self.reload = true;
        }, 100);
      },
      toBackStage(path = "") {
        typeof path === "string" || (path = "");
        const address = self.systemConfig?.address;
        if (!address) {
          return;
        }
        const token = self.$store.getters.token || ''
        path = path?.startsWith("/") ? path.slice(1) : path;
        path = address + '/' + path;

        if (token) {
          path += path.indexOf('?') >= 0 ? ('&pass=' + token) : ('?pass=' + token)
        }
        if (!path) {
          return;
        }
        this.winOpen(path);
      },
      scrollTop(top = 0) {
        const app = document.querySelector('#app');
        if (!app) {
          return
        }
        app.scrollTop = top;
      }
    };
  },
};
</script>

<style lang="less">
:root {
  --layout-width: 1200px;
  --layout-bg-color: #f9f9f9;
  --half-layout: calc(var(--layout-width) / 2);
  --app-main-bg-color: #fff;
  --header-height: 40px;
  --header-bg-color: var(--layout-bg-color);
  --footer-height: 180px;
  --footer-bg-color: #4d4d4d;
  --search-height: 96px;
  --tab-height: 45px;
  --classify-width: 238px;
  --d-border-color: #F3F3F3;
  --kv-height: 480px;
}

#app {
  font-family: system-ui, -apple-system, SourceHanSansCN-Bold, SourceHanSansCN;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: #2c3e50;


  * {
    box-sizing: border-box;
  }

  &::-webkit-scrollbar {
    background-color: #fff;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 6px;
    width: 6px;
  }
}
</style>
