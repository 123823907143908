import request from '@/utils/request'
export default {
    my() {
        return request({
            url: `/shop/queryShopDetail`,
            method: "post",
        });
    },
    // 注册
    register(data) {
        return request({
            url: "/web/shop/register",
            method: "post",
            data,
        });
    },
    list(data) {
        return request({
            url: "/web/shop/list/shops",
            method: "post",
            data,
        });
    },
    search(data) {
      return request({
        url: "/web/shop/search",
        method: "post",
        data,
      });
    },
    detail(shopId) {
        return request({
            url: `/web/shop/list/shop/${shopId}`,
            method: "post",
        });
    },
    brandList(data) {
        return request({
            url: "/web/shop/brand/agent/list",
            method: "post",
            data,
        });
    },
    canOpen(data) {
        return request({
            url: "/shop/is-create",
            method: "post",
            data,
        });
    },
    brandAgentApply(data) {
        return request({
            url: "/shop/brand/agent/apply",
            method: "post",
            data,
        });
    },
    bannerList(data) {
        return request({
            url: "/web/operations/shop/queryShopProductPage",
            method: "post",
            data,
        });
    },
    productList(data) {
        return request({
            url: '/web/pass/shop/product/list',
            method: 'post',
            data
        })
    },
    newsList(data) {
        return request({
            url: '/web/shop/information/queryInformationSection',
            method: 'post',
            data
        })
    },
    newsDetail(data) {
        return request({
            url: '/web/shop/information/incrReadCount',
            method: 'post',
            data
        })
    },
    newsCategoryList(params) {
        return request({
            url: '/web/shop/information/type/getInformationTypeParamMap',
            method: 'get',
            params
        })
    },
    productCategoryList(params) {
        return request({
            url: '/web/shop/classify/getCategoryProductManagementNode',
            method: 'get',
            params
        })
    },
    contact(params) {
        return request({
            url: '/web/customer/contact/getContactByShopId',
            method: 'get',
            params
        })
    },
    queryCooperateShops(data) {
        return request({
            url: '/web/shop/queryCooperateShops',
            method: 'post',
            data
        })
    },
    cooperationApply(data) {
        return request({
            url: '/shop/cooperate/apply/apply',
            method: 'post',
            data
        })
    },
    sieveModule(data) {
      return request({
        url: '/web/pass/shop/sieveModule',
        method: 'post',
        data
      })
    },
  /**
   * 平台货源店铺列表
   * @returns {data: [{id: 1, name: '店铺名称'}]}
   */
  platformSupplyShopList() {
        return request({
            url: '/web/pass/platform/supply/list',
            method: 'get',
        });
    }
}
