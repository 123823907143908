<template>
  <el-dialog :visible.sync="open" width="800px" top="10vh" title="选择身份" :close-on-press-escape="false"
    :close-on-click-modal="false" :destroy-on-close="true" custom-class="open-shop-dialog">
    <div class="step-wrapper">
      <div class="step-title font14 mb20">注册流程</div>
      <el-steps :active="step" class="font14" align-center>
        <el-step title="选择身份">
          <template #icon>
            <c-image alt="选择身份" :src="require('@/assets/images/open-step-1.png')" />
          </template>
        </el-step>
        <el-step title="提交认证信息">
          <template #icon>
            <c-image alt="提交认证信息" :src="require('@/assets/images/open-step-2.png')" />
          </template>
        </el-step>
<!--        <el-step title="缴纳开店费用/保证金">-->
<!--          <template #icon>-->
<!--            <c-image alt="缴纳开店费用/保证金" :src="require('@/assets/images/open-step-3.png')" />-->
<!--          </template>-->
<!--        </el-step>-->
        <el-step title="平台审核">
          <template #icon>
            <c-image alt="平台审核" :src="require('@/assets/images/open-step-4.png')" />
          </template>
        </el-step>
      </el-steps>
    </div>
    <div class="desc-wrapper font14">
      <div class="desc-title">说明</div>
      <div class="desc-item mt5">
        如果你是厂家或品牌方，请选择品牌商。品牌商可以发布塑料总汇商品、批采拼单商品、零售商品；除直接销售外，还可发展经销商、通过平台的分享功能让他人帮你销售商品。
      </div>
      <div class="desc-item mt10">
        如果你是渠道商，或者是想要通过经销平台品牌商商品赚取利润的公司或个人，请选择经销商。经销商可以发布零售商品，同时也可通过平台的分享功能让他人帮你销售商品。
      </div>
    </div>
    <div class="identity-wrapper">
      <div class="identity-title">请选择</div>
      <el-radio-group v-model="identity" class="mt15" fill="#0099F5">
        <el-radio-button label="1">建材品牌商</el-radio-button>
        <el-radio-button label="0">经销商</el-radio-button>
      </el-radio-group>
    </div>
    <div class="mt20 font14 mb10 flex v-center">
      <div class="flex0">邀请码:</div>
      <el-input class="ml10" size="mini" style="width: 100px;" v-model="inviteCode" :disabled="!!initInviteCode"
        maxlength="4" clearable />
    </div>
    <div class="next-btn-wrapper" @click.stop="handleNext">
      <el-button class="next-btn w100" type="primary"> 下一步 </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    next: {
      type: String,
      default: "/shop/open",
    },
  },
  data() {
    return {
      step: 0,
      identity: "1",
      inviteCode: '',
      initInviteCode: ''
    };
  },
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    handleNext() {
      if (this.inviteCode && !/^[0-9a-zA-Z]{4}$/.test(this.inviteCode)) {
        return this.$message.warning('邀请码为4位数字或字母')
      }
      this.open = false;
      this.winOpen(`${this.next}?i=${this.identity}${this.inviteCode ? '&c=' + this.inviteCode : ''}`);
    },
  },
  created() {
    const i = this.$storage.get('i')
    if (!i) {
      return
    }
    this.inviteCode = this.$route.query.i || i;
    this.initInviteCode = this.inviteCode;
  }
};
</script>

<style lang="less">
.open-shop-dialog {
  .el-dialog__header {
    padding: 40px 20px 10px 40px;

    .el-dialog__close {
      font-size: 20px !important;
    }
  }

  .el-dialog__body {
    padding: 30px 40px;
  }

  .step-wrapper {
    width: 600px;

    .el-steps {
      margin-left: -30px;
    }

    .step-title {
      color: #4c4c4c;
    }

    .el-step__icon {
      width: 90px;
      height: 90px;
      // background: #00c2f5;
      border: none;

      .el-step__icon-inner {
        font-size: 22px;
        color: #ffffff;
      }
    }

    .el-step__line {
      height: 1px;
      width: 30%;
      background-color: #e2f2ff;
      top: 50%;
      left: 70%;
      transform: translate(50%, -50%);
    }

    .el-step__title {
      font-size: 14px;
      color: #4c4c4c !important;
    }
  }

  .desc-wrapper {
    margin-top: 65px;

    .desc-title {
      color: #4c4c4c;
    }

    .desc-item {
      color: #8a8a8a;
    }
  }

  .identity-wrapper {
    margin-top: 55px;

    .identity-title {
      color: #4c4c4c;
    }

    .el-radio-button {
      margin-right: 50px;
      border-radius: 1px;
      border: 1px solid #0099f5;

      .el-radio-button__inner {
        color: #0099f5;
        border: none;
        border-radius: 0;
      }

      &.is-active {
        .el-radio-button__inner {
          color: #fff;
        }
      }
    }
  }

  .next-btn-wrapper {
    margin-top: 60px;
    padding: 0 70px;

    .next-btn {
      height: 50x;
    }
  }
}
</style>
